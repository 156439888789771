import _ from 'lodash';
import * as moment from 'moment';
import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      session: null,
      flowType: null,
      primaryMembership: {
        email: null,
        personal_email: null,
        user: null,
      },
    };
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },

    updatePrimaryMembership(state, { key, value }) {
      state.primaryMembership[key] = value;
    },

    resetPrimaryMembership(state) {
      state.primaryMembership = {
        email: null,
        personal_email: null,
        user: null,
      };
    },
  },

  actions: {
    deactivateSessionEvent({ state, commit }, { event }) {
      const originalEvent = _.cloneDeep(event);
      // Initial FE removal to avoid delay
      commit('onboardingEventsCalendar/updateEvent', { eventId: event.id, data: { active: false } }, { root: true });
      return new Promise(() => {
        const url = `/${state.flowType.routeResource}/session_events/${event.id}`;
        Network.delete(url, {}, {
          success: (data) => {
            // Re-update it with value from BE, just to make sure there's no funny business
            commit('onboardingEventsCalendar/updateEvent', { eventId: event.id, data }, { root: true });
          },
          error: (error) => {
            commit('onboardingEventsCalendar/updateEvent', { eventId: event.id, data: originalEvent }, { root: true });
            dispatch('setErrorToast', null, { root: true });
          },
        });
      });
    },

    triggerSessionEvents({ state, commit, dispatch }, { events }) {
      const event = events[0];
      const remainingEvents = events.slice(1);

      commit('update', { key: 'formSubmitted', value: true }, { root: true });
      return new Promise(() => {
        const url = `/${state.flowType.routeResource}/session_events/${event.id}/trigger`;
        Network.post(url, {}, {
          success: (data) => {
            event.triggering = false;
            commit('onboardingEventsCalendar/updateEvent', { eventId: event.id, data }, { root: true });
          },
          error: (error) => {
            event.triggering = false;
            dispatch('setErrorToast', null, { root: true });
          },
          complete: () => {
            if (remainingEvents.length) {
              dispatch('triggerSessionEvents', { events: remainingEvents });
            } else {
              commit('update', { key: 'formSubmitted', value: false }, { root: true });
            }
          },
        });
      });
    },

    rescheduleEvent({ state, commit, dispatch }, { event, day }) {
      const originalEvent = _.cloneDeep(event);
      // Initial FE removal to avoid delay
      commit('onboardingEventsCalendar/updateEvent', { eventId: event.id, data: { occurs_on: day } }, { root: true });
      return new Promise(() => {
        const url = `/${state.flowType.routeResource}/session_events/${event.id}`;
        const params = { onboarding_session_event: { occurs_at: day } };
        Network.put(url, params, {
          success: (data) => {
            // Re-update it with value from BE, just to make sure there's no funny business
            commit('onboardingEventsCalendar/updateEvent', { eventId: event.id, data }, { root: true });
          },
          error: (error) => {
            commit('onboardingEventsCalendar/updateEvent', { eventId: event.id, data: originalEvent }, { root: true });
            dispatch('setErrorToast', null, { root: true });
          },
        });
      });
    },
  },
};
