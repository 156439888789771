export default {
  namespaced: true,

  state() {
    return {
      channels: [],
    };
  },

  mutations: {
    addNewChannel(state, channelName) {
      // TODO: Account for these new channels when saving the record
      state.channels.push({ name: channelName, isNew: true });
    },
  },

  actions: {
  },
};
