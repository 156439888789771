import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      openSelectorId: null, // Randomly generated uuid for instances of the selector components to ensure only one instance is open at once
      searching: false,
      searchCache: {},
    };
  },

  mutations: {
    cacheSearchResult(state, { path, data }) {
      state.searchCache[path] = data;
    },
  },
};
