import _ from 'lodash';
import * as moment from 'moment';

export default {
  namespaced: true,

  state() {
    return {
      calendarType: null,
      events: null,
      roles: null,
      tags: null,
      allowFiltering: false,
      viewMode: null,
      startDay: null,
      startDate: null,
      firstWeek: null,
      lastWeek: null,
    };
  },

  getters: {
    isForSession(state) {
      return state.calendarType === 'session';
    },

    isForTagAsDates(state) {
      return state.calendarType === 'tagAsDates';
    },

    startDay(state, getters) {
      if (getters.isForSession || getters.isForTagAsDates) {
        // In these situations, the calendar is centered around a startDate.
        // The startDay can be calculated based on that date's day of the week.
        // NOTE: moment zero-indexes Sunday, Donut zero-indexes Monday. Subtract 1 accordingly.
        return moment(state.startDate, 'MM/DD/YY').day() - 1;
      }
      // Otherwise, the calendar is already based around a startDay. We have it in state.
      return state.startDay;
    },

    // Event Filtering, Sorting, Grouping
    filteredEvents(state) {
      if (state.allowFiltering) {
        // Only relevant on the events index page
        const checkedRoleIds = state.roles.filter(r => r.checked).map(r => r.id);
        const checkedTagIds = state.tags.filter(t => t.checked).map(t => t.id);
        return state.events.filter((event) => {
          if (!event.active) return false;

          if (!_.intersection(event.role_ids, checkedRoleIds).length) return false;
          if (!_.intersection(event.tag_ids, checkedTagIds).length) return false;

          return true;
        });
      }
        return state.events.filter(e => e.active);
    },
    sortedEvents(_state, getters) {
      return _.sortBy(getters.filteredEvents, ['days_later', 'minute_in_day']);
    },
    groupedEvents(state, getters) {
      if (!state.events) return;

      const groups = {};
      for (const event of getters.sortedEvents) {
        const groupingKey = state.calendarType === 'session' ? event.occurs_on : event.days_later;
        if (!groups[groupingKey]) groups[groupingKey] = [];

        groups[groupingKey].push(event);
      }
      return groups;
    },

    // Date Ranges
    earliestDayMin(state) {
      let min = 0;
      for (const event of state.events) {
        if (event.days_later < min) min = event.days_later;
      }
      return min;
    },
    firstWeekMin(_state, getters) {
      const week = (Math.floor((getters.earliestDayMin + getters.startDay) / -5));
      if (week < 0) return 0;
      return week;
    },
    latestDayMin(state) {
      let min = 0;
      for (const event of state.events) {
        if (event.days_later > min) min = event.days_later;
      }
      return min;
    },
    lastWeekMin(_state, getters) {
      const week = Math.floor((getters.latestDayMin + getters.startDay) / 5) + 1;
      if (week < 1) return 1;
      return week;
    },

    // Misc.
    hasMissedEvents(state) {
      if (state.events) return state.events.some(e => e.active && e.skipped);
    },

    hasRetriableEvents(state) {
      if (state.events) return state.events.some(e => e.active && e.skipped && e.manually_sendable);
    },
  },

  mutations: {
    toggleFilter(state, { recordId, recordType, value }) {
      // Only relevant on the events index page
      const idx = _.findIndex(state[recordType], record => record.id === recordId);
      const effectiveValue = value || !state[recordType][idx].checked;
      state[recordType][idx].checked = effectiveValue;
    },

    updateEvent(state, { eventId, data }) {
      const event = _.find(state.events, e => e.id === eventId);
      Object.assign(event, data);
    },
  },

  actions: {
    changeCalendarType({ state, commit }, { calendarType }) {
      // startDay will always be there on page load, regardless of calendarType.
      // When switching from `tag` to `tagAsDates`, we may need to establish a startDate value as well.
      if (calendarType === 'tagAsDates' && !state.startDate) {
        // The default value of startDate here is the Monday of the following week
        const startDate = moment().add(7, 'd').day(1).format('MM/DD/YY');
        commit('update', { module: 'onboardingEventsCalendar', key: 'startDate', value: startDate }, { root: true });
      }
      commit('update', { module: 'onboardingEventsCalendar', key: 'calendarType', value: calendarType }, { root: true });
    },
  },
};
