export default {
  namespaced: true,

  state() {
    return {
      slackAuth: {},
    };
  },

  getters: {
    slackScopeMissing: (state) => (scope, contextName) => {
      const context = state.slackAuth[contextName || 'base'];
      return context?.scopes?.includes(scope) || false;
    },
  },

  actions: {
    openPermissionsModal({ state, commit }, {
 contextName, missingScope, source, description, dismissable = true,
}) {
      const context = state.slackAuth[contextName || 'base'];
      if (!(context?.path && context?.scopes?.length)) return;

      const primaryMissingScope = missingScope || context.scopes[0];
      commit('updateModal', {
        visible: true,
        subcomponent: 'the-permissions-modal',
        size: 'lg',
        dismissable,
        data: {
          missingScope: primaryMissingScope,
          redirectUrl: context.path,
          trackingProperties: { source },
          showCancel: dismissable,
          description,
        },
      }, { root: true });
    },
  },
};
