import API from 'lib/api/api';

export default {
  namespaced: true,

  state() {
    return {
      files: [], // Currently there is only one file upload input per page
      fetchingData: false,
      sizeLimit: 100000000, // 100 MB (not MiB). Some uses of the uploader override this.
      recordUpdateMutation: null,
      importAction: null,
      sorting: false,
    };
  },

  getters: {
    uploadedFiles(state) {
      return state.files.filter(file => !file.errors && file.state !== 'needs_processing');
    },

    processingFiles(state) {
      return state.files.filter(file => !file.errors && file.state === 'needs_processing');
    },

    currentFileCollectionSize(state) {
      return state.files.reduce((total, f) => total + f.file_size, 0);
    },

    atFileSizeLimit(state, getters) {
      return getters.currentFileCollectionSize >= state.sizeLimit;
    },
  },

  mutations: {
    setFiles(state, files) {
      state.files = files;
    },

    addFile(state, file) {
      state.files = state.files.concat(file);
    },

    removeFile(state, file) {
      state.files = state.files.filter(f => (f?.id?.toString() !== file.id.toString()));
    },

    replaceFile(state, file) {
      state.files = state.files.map(f => (f.id === file.id ? file : f));
    },

    moveFile(state, { file, direction }) {
      const currentIdx = state.files.findIndex(f => f.id === file.id);
      const newIdx = currentIdx + direction; // Direction is either -1 (up) or 1 (down)
      state.files[currentIdx] = state.files[newIdx];
      state.files[newIdx] = file;
    },

    updateFetchingData(state, bool) {
      state.fetchingData = bool;
    },

    updateRecordUpdateMutation(state, val) {
      state.recordUpdateMutation = val;
    },

    updateImportAction(state, val) {
      state.importAction = val;
    },
  },

  actions: {
    uploadFiles({ state, commit, dispatch, getters }, {
     uploadEndpoint, files, acl, allowMultiple, uploadToSlack,
    }) {
      commit('updateFetchingData', true);
      commit('setFiles', getters.uploadedFiles); // Remove previous files with errors from the uploads list

      // Calculate the size of the newly uploaded files
      let totalSize = 0;
      const fileTypes = [];
      Object.values(files).forEach((f) => {
        totalSize += f.size;
        fileTypes.push(f.type);
      });

      const allFilesSize = totalSize + getters.currentFileCollectionSize;
      const overSizeLimit = allFilesSize > state.sizeLimit;
      window.mixpanel.track('Attempt to upload file', { numFiles: files.length, sizeLimit: state.sizeLimit, size: totalSize, allFilesSize, overSizeLimit, uploadToSlack, fileTypes });
      if (overSizeLimit) {
        dispatch('setErrorToast', `File size limit exceeded (max ${Math.floor(state.sizeLimit / 1000000)} MB total for all files).`, { root: true });
        commit('updateFetchingData', false);
      } else if (files.length === 0) {
        dispatch('setErrorToast', 'Please select a file for upload.', { root: true });
        commit('updateFetchingData', false);
      } else {
        return new Promise((resolve, reject) => {
          API[uploadEndpoint.base][uploadEndpoint.method]({ files, acl, upload_to_slack: uploadToSlack }, {
            success: (res) => {
              if (res.assets.length > 0) {
                res.assets.forEach((a) => {
                  if (allowMultiple) {
                    commit('addFile', a);
                  } else {
                    commit('setFiles', [a]);
                  }
                  if (a.state === 'needs_processing') {
                    dispatch('pollForAsset', { id: a.id, uploadEndpoint });
                  }
                });
                commit(state.recordUpdateMutation, getters.uploadedFiles, { root: true });
              }
              if (res.importId) {
                // NOTE: Make sure to call `commit('uploads/updateFetchingData', false);` in the import action
                dispatch(state.importAction, res.importId, { root: true });
              } else {
                commit('updateFetchingData', false);
              }
              resolve(true);
            },
            error: (res) => {
              dispatch('setErrorToast', 'Failed to upload file.', { root: true });
              commit('updateFetchingData', false);
              resolve(false);
            },
          });
        });
      }
    },

    pollForAsset({ state, commit, getters, dispatch }, { id, uploadEndpoint }) {
      let pollCount = 0;
      let polling = setInterval(() => {
        API[uploadEndpoint.base].getAssets(id, {
          success: (data) => {
            // Continue polling until the asset has the state 'uploaded', or 30 seconds has passed
            pollCount += 1;
            if (data.asset.state === 'uploaded' || data.asset.state === 'failed_processing' || pollCount === 10) {
              // If the asset has not processed in time or processing has failed, add an error
              if ((data.asset.state !== 'uploaded' && pollCount === 10) || data.asset.state === 'failed_processing') {
                data.asset = { ...data.asset, errors: { failed_processing: true } };
              }
              commit('replaceFile', data.asset);
              clearInterval(polling);
              polling = null;
              commit(state.recordUpdateMutation, getters.uploadedFiles, { root: true });
            }
          },
          error: (error) => {
            dispatch('setErrorToast', null, { root: true });
          },
        });
      }, 3000); // Call every 3 seconds
    },

    removeFile({ state, commit, getters }, file) {
      commit('removeFile', file);
      commit(state.recordUpdateMutation, getters.uploadedFiles, { root: true });
    },

    moveFile({ state, commit, getters }, { file, direction }) {
      commit('moveFile', { file, direction });
      commit(state.recordUpdateMutation, getters.uploadedFiles, { root: true });
    },
  },
};
